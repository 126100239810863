import styled from "../../styles/styled-components";
import { mediaQueries } from "../../styles/media-queries";
import { H1, H2, H3 } from "../../styles/global";

const HomeStyle = styled.div`
	${H2} {
		font-family: HLN-Medium;
		font-size: 3em;
	}

	${H3} {
		color: #FFF;
		text-transform: uppercase;
		text-align: center;
		margin: 40px 0;
	}

	.game-cover {
		color: #FFF;
		text-align: center;
		margin: 0;
		min-height: 70vh;
		padding: 60px;
		
		display: flex;
		justify-content: center;
		align-items: end;

		p {
			font-size: 1.1em;
		}

		// background: linear-gradient(rgba(0,0,0,0.1),rgba(0,0,0,1)), url(https://goudenspelen.s3-eu-west-1.amazonaws.com/files/cover/${(props: any) => props.application.competition.competitionFeed}_${(props: any) => props.application.competition.seasonId}_cover_nl_desktop.jpg) no-repeat top center;
		background: url(https://goudenspelen.s3-eu-west-1.amazonaws.com/files/cover/${(props: any) => props.application.competition.competitionFeed}_${(props: any) => props.application.competition.seasonId}_cover_nl_desktop.jpg) no-repeat top center;
		backgroundSize: cover;

		@media (max-width: 425px) {
			height: 90vh;
      padding: 10px;
			background: url(https://goudenspelen.s3-eu-west-1.amazonaws.com/files/cover/${(props: any) => props.application.competition.competitionFeed}_${(props: any) => props.application.competition.seasonId}_cover_nl_desktop.jpg) no-repeat top center;
			// background: linear-gradient(rgba(0,0,0,0.1),rgba(0,0,0,1)), url(https://goudenspelen.s3-eu-west-1.amazonaws.com/files/cover/${(props: any) => props.application.competition.competitionFeed}_${(props: any) => props.application.competition.seasonId}_cover_nl_mobile.jpg) no-repeat top center;
			// background: url(https://goudenspelen.s3-eu-west-1.amazonaws.com/files/cover/${(props: any) => props.application.competition.competitionFeed}_${(props: any) => props.application.competition.seasonId}_cover_nl_mobile.jpg) no-repeat top center;
		}

		@media (max-width: 350px) {
			height: 80vh;
			padding: 20px;
			background: url(https://goudenspelen.s3-eu-west-1.amazonaws.com/files/cover/${(props: any) => props.application.competition.competitionFeed}_${(props: any) => props.application.competition.seasonId}_cover_nl_desktop.jpg) no-repeat top center;
			// background: linear-gradient(rgba(0,0,0,0.1),rgba(0,0,0,1)), url(https://goudenspelen.s3-eu-west-1.amazonaws.com/files/cover/${(props: any) => props.application.competition.competitionFeed}_${(props: any) => props.application.competition.seasonId}_cover_nl_mobile.jpg) no-repeat top center;
			// background: url(https://goudenspelen.s3-eu-west-1.amazonaws.com/files/cover/${(props: any) => props.application.competition.competitionFeed}_${(props: any) => props.application.competition.seasonId}_cover_nl_mobile.jpg) no-repeat top center;
		
			${H2} {
				font-size: 2em;
			}

			p {
				font-size: 0.9em;
			}
		}
	}

	.register-form {
		padding: 20px 40px;
	}

	.how-to-play {
		padding: 20px;

		border-radius: 10px;

		.step {
			color: #FFF;
			background-color: #08003b;
			text-align: center;
			min-height: 250px;
		}

		.step .label {
			top: -30px;
			position: relative;
			width: 80%;
			margin: 0 auto;

			h3 {
				color: #FFF;
				background: #F00;
				//background: transparent linear-gradient(83deg, #F5D555 0%, #F5D555 0%, #FDEFA7 48%, #F4CF41 100%) 0% 0% no-repeat padding-box;
				padding: 20px 0;
			}
		}

		.step img {
			width: 100%;
		}

		.step p {
			padding: 0 20px 20px;
		}
	}
` as any;

const Cover = styled.div`
	text-align: center;
	position: relative;
	height: auto;
	background-color: black;

	img {
		min-width: 100%;
	}

	.overlay {
		display: flex;
		flex-direction: column;
		align-items: center;

		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		padding: 20px 10px;

		.bottom {
			flex: 1;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			align-items: center;
		}

		${H1} {
			font-size: 20px;
			color: white;
			
			@media ${mediaQueries.tablet} {
				font-size: 30px;
			}

			@media ${mediaQueries.desktop} {
				margin-top: 40px;
			}
		}

		${H1}, p {
			text-shadow: 2px 2px 20px #000000;
			text-align: center;
		}

		p {
			font-size: 1em !important;
			margin: 20px 0 0;


			@media ${mediaQueries.tablet} {
				font-size: 1.5em !important;
			}
		}

		button {
			font-size: 1.5em;
			height: auto;
		}

		button:hover {
			color: #fff;
		}
	}
`;

const CardStyle = styled.div`
	margin-bottom: 40px;
	background-color: #F5F5F5;	
	overflow: hidden;

	img {
		width: 50px;
	}

	.ant-card-meta-title {
		display: inline-block;
		background-color: white;
		padding: 5px;
		color: ${props => props.theme.primaryColor};
		font-weight: bold;
		margin: 10px 0 10px;
	}

	.ant-card-body {
		text-align: justify;

		@media ${mediaQueries.mobileL} {
			height: 140px;
		}

		@media (min-width: 576px) {
			height: 240px;
		}

		@media (min-width: 768px) {
			height: 320px;
		}

		@media (min-width: 1024px) {
			height: 240px;
		}

		padding: 10px;
	}
`;

export {
	HomeStyle,
	Cover,
	CardStyle
};